<template>
  <div class="OrderList">
    <GlobalInfoBar title="议价订单" content="管理和查看发起议价申请的订单" />
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <OrderFrom
          :classList="classList"
          :tabIndex="tabIndex"
          ref="childForm"
        />
      </div>
      <div class="caseOrder">
        <OrderTable
          @getUnPayUserOrders="getUnPayUserOrders"
          @tabChange="tabChange"
        />
      </div>
    </GlobalChunk>
  </div>
</template>

<script>
import OrderFrom from "./OrderFrom";
import OrderTable from "./OrderTable";
export default {
  name: "OrderList",
  components: { OrderFrom, OrderTable },
  data() {
    return {
      classList: null,
      topIndex: 1, //
      tabIndex: 0, //
      scollData: [
        {
          name: "非最高报价订单",
          id: 1,
        },
        {
          name: "非最高报价订单分析",
          id: 2,
        },
      ],
      merchantname: "",
    };
  },
  created() {
    this.merchantname = this.$route.query.name;
  },
  methods: {
    selectOnheight(id) {
      this.topIndex = id;
    },
    getUnPayUserOrders(e) {
      this.UnPayUserOrders = e;
    },
    // tab改变了
    tabChange(e) {
      this.tabIndex = e;
      console.log(e, "eeeee");
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.OrderList {
  .case {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .case-title {
      display: flex;

      .case-title_icon {
        width: 20px;
        height: 20px;
      }

      .case-title_max {
        font-weight: bold;
        color: #333333;
        font-size: 18px;
        width: 80px;
        height: 17px;
        line-height: 17px;
        font-family: FZLanTingHei-B-GBK;
      }
    }
  }

  .caseOrder {
    margin: 0 5px;
    background: #f5f6fa;
    border-radius: 10px;
  }
}

.taber_scoll {
  margin-bottom: 20px;
  .ts_center {
    cursor: pointer;
    margin-right: 20px;
    text-align: center;
    width: 150px;
    height: 36px;
    border: 1px solid #0981ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #0981ff;
    line-height: 36px;
  }
  .selectStyle {
    cursor: pointer;
    margin-right: 20px;
    text-align: center;
    width: 150px;
    height: 36px;
    border: 1px solid #0981ff;
    border-radius: 4px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ffffff;
    background: #0981ff;
    line-height: 36px;
  }
}
.top_model {
  margin-bottom: 10px;
  width: 100%;
  height: 66px;
  background: #e4ecfd;
  border-radius: 33px;
  .left {
    float: left;
    margin-left: 2%;
    width: 50%;
    height: 66px;
    line-height: 66px;
    .store_le {
      div {
        font-size: 22px;
        font-family: FZLanTingHei-DB-GBK;
        font-weight: 400;
        color: #333333;
      }
      div:last-child {
        font-size: 22px;
        font-family: FZLanTingHei-DB-GBK;
        font-weight: 400;
        color: rgba(9, 129, 255, 1);
      }
    }
    .poople_le {
      margin-left: 90px;
      div {
        font-size: 22px;
        font-family: FZLanTingHei-DB-GBK;
        font-weight: 400;
        color: #333333;
      }
      div:last-child {
        font-size: 22px;
        font-family: FZLanTingHei-DB-GBK;
        font-weight: 400;
        color: rgba(9, 129, 255, 1);
      }
    }
  }
  .right {
    height: 66px;
    line-height: 66px;
    float: right;
    margin-right: 28px;
    font-size: 16px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ff687b;
  }
}
.dis {
  display: flex;
}
.al_c {
  align-items: center;
}
.ju_s {
  justify-content: space-between;
}
.ju_c {
  justify-content: center;
}
</style>
