<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      ref="orderForm"
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="80"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
        :label-width="'60px'"
        label="商家"
        slot="createCompanyId"
        style="margin: 0"
      >
        <el-select clearable filterable v-model="createCompanyId">
          <el-option
            v-for="item in companyList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label-width="'80px'"
        label="订单编号"
        slot="orderNo"
        style="margin: 0"
      >
      <el-input
        placeholder="请输入订单编号"
        v-model="orderNo"
        clearable>
      </el-input>
      </el-form-item>
      <el-form-item label="订单状态" slot="state" style="margin: 0">
        <el-select
          v-model="state"
          placeholder="请选择订单状态"
          clearable
        >
          <el-option
            v-for="item in orderStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </GlobalForm>
  </div>
</template>
  
<script>
import moment from "moment";
import _api from "@/utils/request";
export default {
  name: "Form",
  props: {
    default: () => {
      return [];
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      orderNo: "",
      companyList: [],
      initData: {
        entryTime: [moment().day(moment().day()).format('YYYY-MM-DD 00:00:00'),moment().format('YYYY-MM-DD 23:59:59')]
      },
      formItemList: [
        { slotName: "createCompanyId" },
        { slotName: "orderNo" },
        {
          key: "imei",
          type: "input",
          labelName: "串号",
          placeholder: "请输入串号",
          lableWidth: "80px",
        },
        {
          key: "outOrderNo",
          type: "input",
          labelName: "外部订单号",
          lableWidth: "100px",
          placeholder: "请输入外部订单号",
        },
        {
          key: 'entryTime',
          type: "pickerOptions",
          labelName: '申请时间',
          placeholder: '请选择申请时间',
          valueFormat:"timestamp",
          clearable: true,
        },
        { slotName: "state" },
        // {
        //   key: "expState",
        //   type: "select",
        //   lableWidth: "80",
        //   labelName: "议价结果",
        //   option: [
        //     {
        //       value: '00',
        //       label: "待审批",
        //     },
        //     {
        //       value: '01',
        //       label: "议价通过",
        //     },
        //     {
        //       value: '02',
        //       label: "议价拒绝",
        //     },
        //   ],
        // },
        {
          key: "auditState",
          type: "select",
          lableWidth: "115",
          labelName: "回收商审批状态",
          option: [
            {
              value: '00',
              label: "待审批",
            },
            {
              value: '01',
              label: "已通过",
            },
            {
              value: '02',
              label: "已拒绝",
            },
          ],
        },
        {
          key: "isPlatformSubsidy",
          type: "select",
          lableWidth: "110",
          labelName: "平台是否补贴",
          option: [
            {
              value: '00',
              label: "是",
            },
            {
              value: '01',
              label: "否",
            },
          ],
        },
      ],
      SeachParams: {},
      state: "", //订单状态
      createCompanyId: "",
      orderStatusList: [],
    };
  },
  created() {
    if(this.$route.query.orderNo){
      this.orderNo = this.$route.query.orderNo;
      this.initData.entryTime = null
    }
    this.getInquiryList();
    this.getStatusList();
    this.handleConfirm(this.initData)

  },
  mounted(){
    
  },
  methods: {

    // 获取状态下拉列表
    getStatusList() {
      _api.orderStatusList().then((res) => {
        let arr = [];
        for (const item of res.data) {
          arr.push({
            value: item.key,
            label: item.value,
          });
        }
        this.orderStatusList = arr;
      });
    },
    // 获取询价商家下拉列表
    getInquiryList() {
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          this.companyList = res.data;
        }
      });
    },
    //查询****
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      let startTime = "";
      let endTime = "";
      if (data.entryTime === null) {
        startTime = ""
        endTime = ""
      } else {
        startTime = moment(data.entryTime[0]).format("x")
        endTime = moment(data.entryTime[1]).format("x")
      }
      const baseRequest = {
        createCompanyId: this.createCompanyId,
        orderNo: this.orderNo,
        imei: data.imei,
        outOrderNo: data.outOrderNo,
        state: this.state,
        endTime: endTime,
        startTime: startTime,
        // expState: data.expState,
        auditState: data.auditState,
        isPlatformSubsidy: data.isPlatformSubsidy,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        disuseLoding: () => {
          if (typeof cd === 'function') {
            cd()
          }
        },
      };
      this.SeachParams = baseRequest;
      this.$store.commit("tagsView/SEACH_PARAMS", baseRequest);
    },
  },
};
</script>
  
<style scoped lang="scss" ref="stylesheet/scss">
.top_aujian {
  overflow: auto;
  width: 225px;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ta_center_con {
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  margin-bottom: 14px;
  color: #333333;
  width: 100px;
  height: 30px;
  background: #f3f3f3;
  border-radius: 4px;
}

.ta_center_action {
  cursor: pointer;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  margin-bottom: 14px;
  color: #0981ff;
  width: 100px;
  height: 30px;
  background: #e4ecfd;
  border-radius: 4px;
}

/deep/.query .el-tag.el-tag--info {
  background-color: #fbfbfb;
  border-color: #cecdcd;
  color: #666666;
}

.baoPrice {
  border: 1px solid #e4ecfd;
  width: 421px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  padding: 3px 10px;

  > img {
    width: 15px;
    height: 15px;
  }
}

.bao_section {
  flex: 1;
  font-size: 12px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #a1a4af;
  // width: 300px;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
}

.pulldown_content {
  width: 265px;
  padding: 0 20px;
  height: 300px;
  position: relative;
}

.fc_input {
  font-size: 12px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px auto;
  width: 225px;
  height: 30px;
  // background: #F9FBFD;
  border: 1px solid #c1d1ff;
  border-radius: 4px;
}

.put_price {
  width: 80px;

  /deep/.el-input__inner {
    border: none !important;
  }
}

.foot_content {
  position: absolute;
  bottom: 0;
  width: 225px;
  height: 95px;
  // box-shadow: 0px 2px 14px 1px rgba(118, 133, 164, 0.31);
}

/deep/.el-dropdown-menu__item {
  line-height: 30px !important;
  padding: 0 !important;
}

.pc_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.GlobalFormDemo {
  /deep/.el-checkbox-group {
    margin-left: 15px;
  }

  // /deep/.el-form-item--small.el-form-item{
  //   margin-bottom: 0;
  // }
  .flex {
    display: flex;
    align-items: center;
  }

  .time-type {
    /deep/.el-input__inner {
      border: none;
    }
  }
}

/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type="number"] {
  -moz-appearance: textfield;
}
</style>
  