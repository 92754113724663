<template>
  <div class="OrderTable">
    <div class="top_bar" style="margin-bottom: 20px">
      <div class="case" style="text-align: right">
        <!-- tab切换 -->
        <GardenSlwct
          :tab-data="tabData"
          :activeIndex="activeIndex"
          width="120px"
          border-radius="40px"
          @tabAction="tabAction"
        >
        </GardenSlwct>
      </div>
      <div>
        <el-button
          size="small"
          type="success"
          icon="el-icon-download"
          :loading="downloadLoading"
          @click="codeSubmit"
          >导出Excel</el-button
        >
      </div>
    </div>
    <GlobalTable
      v-if="tableShow"
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="orderTableList"
      :currentPage="pages.pageNum"
      :total="pages.total"
      @handleCurrentChange="handleCurrentChange"
      >
      <el-table-column label="机型/订单号" slot="model" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.phoneName }}</div>
          <div class="btn" @click="handleClick(row)">{{ row.orderNo }}</div>
        </template>
      </el-table-column>
      <el-table-column label="串号/外部单号" slot="imei" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.imei }}</div>
          <div>外部：{{ row.outOrderNo }}</div>
        </template>
      </el-table-column>
      <el-table-column label="下单商户" slot="createCompanyName" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            class="item"
            effect="dark"
            :content="row.createCompanyName"
            placement="top"
          >
            <div class="nowrap-ellipsis">
              {{ row.createCompanyName }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column slot="state" label="订单状态" align="center" width="90px">
        <template slot-scope="{ row }">
          <span v-if="row.state === '00'">待确认</span>
          <span v-else-if="row.state === '01'">已确认</span>
          <span v-else-if="row.state === '02'">待支付</span>
          <span v-else-if="row.state === '03'">已绑码</span>
          <span v-else-if="row.state === '04'">已收货</span>
          <span v-else-if="row.state === '05'">降价收货</span>
          <span v-else-if="row.state === '10'">已取消</span>
          <span v-else-if="row.state === '20'">已作废</span>
          <span v-else-if="row.state === '30'">已退回</span>
        </template>
      </el-table-column>
      <el-table-column slot="prevChxPrice" label="首次推送报价" align="center" width="100px">
        <template slot-scope="{ row }">
          <span v-if="row.prevChxPrice">￥{{ row.prevChxPrice }}</span>
          <span v-else>--</span>
          <div v-if="row.prevMerchantName">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.prevMerchantName"
              placement="top"
            >
              <div class="nowrap-ellipsis">
                {{ row.prevMerchantName }}
              </div>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column slot="negotiatePrice" label="议价申请报价" align="center">
        <template slot-scope="{ row }">
          <div>￥{{ row.expectedPrice }}</div>
          <div class="btn" @click="seeRemark(row)">议价备注</div>
        </template>
      </el-table-column>
      <el-table-column slot="chxPrice" label="议价推送报价" align="center" width="100px">
        <template slot-scope="{ row }">
          <div v-if="row.chxPrice">￥{{ row.chxPrice }}</div>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column slot="timeout" label="议价审批时长" align="center" width="100px">
        <template slot-scope="{ row }">
          <div>{{ getTimes(row.timeout) }}</div>
        </template>
      </el-table-column>
      <el-table-column slot="expState" label="议价结果" align="center" width="100px">
        <template slot-scope="{ row }">
          <div>{{ row.expState=='00'?'待审批':row.expState=='01'?'议价通过':'议价拒绝' }}</div>
        </template>
      </el-table-column>
      <el-table-column slot="auditTime" v-if="expState=='01' || expState=='02'" :label="expState=='01'?'议价通过时间':'议价拒绝时间'" align="center" width="100px">
        <template slot-scope="{ row }">
          <div v-if="row.auditTime">{{ row.auditTime }}</div>
          <div v-else>--</div>
          <div v-if="expState=='02' && row.rejectType === '01'">超时自动拒绝</div>
        </template>
      </el-table-column>
      <el-table-column slot="auditState" label="回收商审批状态" align="center">
        <template slot-scope="{ row }">
          <div :class="row.auditState=='00'?'':row.auditState=='01'?'success':'reject'">{{ row.auditState=='00'?'待审批':row.auditState=='01'?'已通过':'已拒绝' }}</div>
          <div v-if="row.merchantName">
            <el-tooltip
              class="item"
              effect="dark"
              :content="row.merchantName"
              placement="top"
            >
              <div class="nowrap-ellipsis">
                {{ row.merchantName }}
              </div>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column slot="platformSubsidyType" label="平台是否补贴" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.isPlatformSubsidy=='00'?'是':row.isPlatformSubsidy=='01'?'否':'' }}</div>
          <div class="success">{{row.platformSubsidyType=='00'?'自动补贴':row.platformSubsidyType=='01'?'手动操作':''}}</div>
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog
      class="remarks_dialog"
      title='议价备注'
      :visible.sync="remarksShow"
      :close-on-click-modal="false"
      width="410px"
      @closed="remarksClosed"
    >
      <div style="margin-bottom: 10px">议价备注：</div>
      <div class="remarks-info">
        <span>{{ expectedRemark || '--' }}</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="remarksShow=false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import _api from "@/utils/request";
import moment from "moment";
import GardenSlwct from "@/components/common/gardenSlwct.vue";
export default {
  name: "OrderTable",
  components: {
    GardenSlwct,
  },
  data() {
    return {
      tabData: [
        {
          text: "全部",
          value: "",
          hideNum: true,
        },
        {
          text: "待审批",
          value: "00",
          hideNum: true,
        },
        {
          text: "议价通过",
          value: "01",
          hideNum: true,
        },
        {
          text: "议价拒绝",
          value: "02",
          hideNum: true,
        },
      ],
      activeIndex: 0, //tab初始选择索引
      orderNo: "",
      pages: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      },
      orderTableList: [],
      loading: false,
      command: "",

      expState: '',
      tableColumns: [
        { slotName: "model" },
        { slotName: "imei" },
        { slotName: "createCompanyName" },
        { slotName: "state" },
        { label: "申请时间", prop: "applyTime" },
        { slotName: "prevChxPrice" },
        { slotName: "negotiatePrice" },
        { slotName: "chxPrice" },
        { slotName: "timeout" },
        { slotName: "expState" },
        { slotName: "auditTime" },
        { slotName: "auditState" },
        { slotName: "platformSubsidyType" },
      ],
      expectedRemark: "",
      remarksShow: false,
      tableShow: true,
      downloadLoading: false
    };
  },
  created() {
    this.handleCurrentChange()
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newval) {
      console.log(newval, "SeachParamsSeachParams");
      this.pages.pageNum = 1
      this.handleCurrentChange();
    },
  },
  methods: {
    // tab状态操作
    tabAction(v) {
      console.log(v);
      this.activeIndex = v;
      this.expState = this.tabData[v].value;
      // 因为状态不同表格列有差别，防止出现列与数据混乱问题，所以先卸载再重新渲染表格
      this.tableShow = false
      this.tableShow = true
      this.handleCurrentChange(1);
    },
    getTimes(t) {
      let h = parseInt((t / 60 / 60) % 24);
      let m = parseInt((t / 60) % 60);
      let s = parseInt(t % 60);
      //三元表达式 补零 如果小于10 则在前边进行补零 如果大于10 则不需要补零
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      return `${m}分${s}秒`;
    },
    //导表
    codeSubmit() {
      const params = {
        expState: this.expState,
        ...this.SeachParams,
      };
      this.downloadLoading = true;
      _api
        .expExcel(params)
        .then((res) => {
          console.log(res);
          const content = res;
          this.codeShow = false;
          this.code = "";
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "导出失败");
              }
            } catch (err) {
              const fileName = "议价订单.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
          this.downloadLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.downloadLoading = false;
        })
    },
    handleClick(row) {
      const { href } = this.$router.resolve({
        path: "/RecallOrder/index",
        query: {
          orderNo: row.orderNo,
        },
      });
      window.open(href, "_blank");
    },
    handleCurrentChange(val) {
      //  暂时
      this.loading = true;
      if (val) {
        this.pages.pageNum = val;
      }
      const vo = {
        orderNo: this.SeachParams.orderNo,
        imei: this.SeachParams.imei,
        outOrderNo: this.SeachParams.outOrderNo,
        createCompanyId: this.SeachParams.createCompanyId,
        state: this.SeachParams.state,
        endTime: this.SeachParams.endTime,
        startTime: this.SeachParams.startTime,
        expState: this.expState,
        auditState: this.SeachParams.auditState,
        isPlatformSubsidy: this.SeachParams.isPlatformSubsidy,
        quoteStartTime: this.SeachParams.quoteStartTime,
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
      };
      _api.getExpOrderList(vo).then((res) => {
        if (res.code === 1) {
          this.orderTableList = res.data.records;
          this.pages.total = res.data.total;
          this.pages.pageNum = res.data.current;
          this.loading = false;
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      });
    },
    seeRemark(row) {
      this.expectedRemark = row.expectedRemark
      this.remarksShow = true
    },
    remarksClosed(){
      this.expectedRemark = ""
    }
  },
};
</script>
  
<style scoped lang="scss" ref="stylesheet/scss">
.OrderTable {
  background: white;

  .top_bar {
    background: white;
    display: flex;
    justify-content: space-between;

    .case {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.nowrap-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.btn {
  color: #0981ff;
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
}
.success{
  color: #0981ff;
}
.reject{
  color: #ff687b;
}
.remarks-info{
  background: #f5f6fa;
  padding: 20px;
  border-radius: 10px;
}
</style>
  